


















import { Component, Vue } from "vue-property-decorator";
import { SwapButtons } from "@/components";
import { Burn, Mint } from "@/components";
import { Action } from "@/types";

@Component({
  components: {
    Burn,
    Mint,
    SwapButtons,
  },
})
export default class Swap extends Vue {
  private currentAction = Action.NULL;
  private Action: typeof Action = Action;

  private get title(): string {
    switch (this.currentAction) {
      case Action.MINT:
        return "Swap your HYD to WHYD";
      case Action.BURN:
        return "Swap your WHYD to HYD";
      default:
        return "Swap";
    }
  }
  private setAction(action: Action) {
    this.currentAction = action;
  }
}
